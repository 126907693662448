import styled from "@emotion/styled";
import { Loader } from "@mantine/core";
import { Suspense } from "react";
import { pdfjs } from "react-pdf";
import Routes from "./router";

// import { CustomerPage } from "./pages/customer";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function App() {
  return (
    <Main>
      <Suspense fallback={<Loader color="dark" size="xl" />}>
        <Routes />
      </Suspense>
    </Main>
  );
}

const Main = styled.main`
  min-width: 100vw;
  min-height: 100vh;
  width: 100vw;
  /* height: 100vh; */

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default App;
