import { Card, Container, Text } from "@mantine/core";

export const NotFound = () => {
    return (
        <Container style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Card shadow="sm" padding="lg" style={{ maxWidth: 400, textAlign: 'center' }} bg="#F2F2F2">
                <Text weight={700} size="xl" mt="md">
                    올바르지 않은 접근 방식입니다.
                </Text>
            </Card>
        </Container>
    )
}