import { Global, css, useTheme } from "@emotion/react";

function GlobalStyle() {
  const theme = useTheme();

  // useEffect(() => {
  //   const html = document.querySelector("html");
  //   html && (html.style.backgroundColor = theme.backgroundColor);
  // }, [theme]);

  const globalCSS = css`
    :root {
      /* fonts */
      --font-noto-sans-cjk-kr: "Noto Sans KR";

      /* font sizes */
      --font-size-smi: max(0.75rem); /*12px*/
      --font-size-base: max(0.875rem); /*14px*/
      --font-size-xl: max(1rem); /*16px*/
      --font-size-lg-1: max(1.125rem); /*18px*/

      /* Colors */
      --color: #fff;
      --color-black: #000;
      --main-color: #2398d2;
      --tit-color: #00abff;
      --gray-color: #808080;
      --cont-text-color: #000417;
      --btn-color: #2ebaff;

      /* border */
      --borderdefault: 1px solid var(--gray-type02);
      --borderactive: 1px solid var(--color-steelblue);

      /* radius */
      --radius-5: 0.3125rem;
      --radius-10: 0.625rem;
    }

    .radius-5 {
      border-radius: var(--radius-5);
      -webkit-border-radius: var(--radius-5);
      -moz-border-radius: var(--radius-5);
      -ms-border-radius: var(--radius-5);
      -o-border-radius: var(--radius-5);
    }

    /* font */
    @font-face {
      font-family: "Noto Sans KR";
      font-style: normal;
      font-weight: 100;
      src: url(../font/NotoSansKR-Thin.woff) format("woff"),
        url(../font/NotoSansKR-Thin.otf) format("opentype");
    }
    @font-face {
      font-family: "Noto Sans KR";
      font-style: normal;
      font-weight: 300;
      src: url(../font/NotoSansKR-Light.woff) format("woff"),
        url(..btn/font/NotoSansKR-Light.otf) format("opentype");
    }
    @font-face {
      font-family: "Noto Sans KR";
      font-style: normal;
      font-weight: 400;
      src: url(../font/NotoSansKR-Regular.woff) format("woff"),
        url(../font/NotoSansKR-Regular.otf) format("opentype");
    }
    @font-face {
      font-family: "Noto Sans KR";
      font-style: normal;
      font-weight: 500;
      src: url(../font/NotoSansKR-Medium.woff2) format("woff2"),
        url(../font/NotoSansKR-Medium.woff) format("woff"),
        url(../font/NotoSansKR-Medium.otf) format("opentype");
    }
    @font-face {
      font-family: "Noto Sans KR";
      font-style: normal;
      font-weight: 700;
      src: url(../font/NotoSansKR-Bold.woff) format("woff2"),
        url(../font/NotoSansKR-Bold.woff) format("woff"),
        url(../font/NotoSansKR-Bold.woff) format("opentype");
    }
    @font-face {
      font-family: 'MBK CorpoS';
      src: url(/font/MBKCorporateS.eot);
      src: url(/font/MBKCorporateS.eot?#iefix) format('embedded-opentype'),
          url(/font/MBKCorporateS.woff) format('woff'),
          url(/font/MBKCorporateS.woff2) format('woff2'),
          url(/font/MBKCorporateS.ttf) format('truetype');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      word-break: keep-all;
      font-family: MBK CorpoS !important;
    }

    body {
      position: relative;
      margin: 0;
      line-height: normal;
      // color: var(--color);
      font-size: var(--font-size-smi);
      font-family: MBK CorpoS !important;
    }

    a {
      text-decoration: none;
      color: black;
    }
    ul {
      list-style-type: none;
    }
    img {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    button {
      border: none;
      background-color: transparent;
      border-radius: 1rem;
      cursor: pointer;
      &:active,
      &:focus {
        outline: none;
      }
    }
    input {
      outline: none;
      &:focus::placeholder {
        color: transparent;
      }
    }
  `;

  return <Global styles={[globalCSS]} />;
}

export default GlobalStyle;
