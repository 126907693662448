import { NotFound } from "pages/NotFound";
import { lazy } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

// 동적 import를 사용하여 컴포넌트 로드
const Landing = lazy(() => import('pages/Landing'));

function Index() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Landing />
          }
        />
        {/* <Route
          path="/"
          element={
            <CustomerPage />
          }
        /> */}
        <Route
          path="*"
          element={
            <NotFound />
          }
        />
      </Routes>

    </Router>
  );
}

export default Index;
